var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order === null)?_c('v-container',[_c('v-row',{staticClass:"pt-5"},[_c('loading-spinner')],1)],1):_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8","xl":"6","md":"8","offset-md":"2","offset-lg":"2","offset-xl":"3"}},[(!_vm.order.isFulfilled)?_c('composite-alert'):_vm._e(),_c('v-card',[_c('v-card-title',{staticClass:"text-break"},[_c('h2',{staticClass:"d-block mx-auto text-h4"},[(_vm.order.isAwaitingPayment)?_c('span',[_vm._v(" Awaiting Payment ")]):_vm._e(),(_vm.order.isFulfilling && _vm.order.isDstv)?_c('span',[_vm._v(" Crediting "),_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("DSTV")]),_vm._v(" Account ")]):_vm._e(),(_vm.order.isFulfilling && _vm.order.isCanalplus)?_c('span',[_vm._v(" Crediting "),_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("CANAL+")]),_vm._v(" Account ")]):_vm._e(),(_vm.order.isFulfilling && _vm.order.isAirtime)?_c('span',[_vm._v(" Transferring Airtime ")]):_vm._e(),(
                                _vm.order.isFulfilling &&
                                (_vm.order.isCryptoSell || _vm.order.isExchange)
                            )?_c('span',[_vm._v(" Transferring Funds ")]):_vm._e(),(_vm.order.isFulfilling && _vm.order.isEneoPrepaid)?_c('span',[_vm._v(" Fetching "),_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("ENEO")]),_vm._v(" Code ")]):_vm._e(),(
                                _vm.order.isFulfilling && _vm.order.isEneoPostpaid
                            )?_c('span',[_vm._v(" Paying "),_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("ENEO")]),_vm._v(" Bill ")]):_vm._e(),(_vm.order.isFulfilling && _vm.order.isCamwater)?_c('span',[_vm._v(" Paying "),_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("Camwater")]),_vm._v(" Bill ")]):_vm._e(),(_vm.order.isFulfilled)?_c('span',[_vm._v(" Order Complete ")]):_vm._e(),(_vm.order.isFailedAtPayment)?_c('span',{staticClass:"red--text"},[_vm._v(" Order Failed ")]):_vm._e()])]),_c('v-card-text',{staticClass:"mt-8"},[(_vm.order.isInProgress)?_c('v-progress-circular',{staticClass:"mx-auto d-block",attrs:{"size":60,"width":4,"color":"primary","indeterminate":""}}):_vm._e(),(_vm.order.isAwaitingPayment)?_c('div',{staticClass:"d-block mx-auto text-center mt-5 mb-8"},[(
                                !_vm.order.requiresPaymentRedirect &&
                                !_vm.order.hasCryptoCurrencyPayment
                            )?_c('p',{staticClass:"title"},[_vm._v(" You will receive a payment request on your phone from our payment processor "),_c('code',[_vm._v(_vm._s(_vm.order.paymentGatewayName))]),_vm._v(" to withdraw "+_vm._s(_vm._f("currency")(_vm.order.paymentAmount))+". ")]):_vm._e(),(
                                !_vm.order.requiresPaymentRedirect &&
                                !_vm.order.hasCryptoCurrencyPayment
                            )?_c('p',{staticClass:"subtitle-1"},[_vm._v(" You can dial "),(_vm.order.isPayedUsingMTNMobileMoney)?_c('a',{staticClass:"text-decoration-none font-weight-bold",attrs:{"href":"tel:*126#"}},[_vm._v("*126#")]):_c('a',{staticClass:"text-decoration-none font-weight-bold",attrs:{"href":"tel:#150*50#"}},[_vm._v("#150*50#")]),_vm._v(" to start the process. It usually takes less than 10 seconds for us to automatically confirm your payment. ")]):_vm._e(),(
                                _vm.order.requiresPaymentRedirect &&
                                _vm.paymentStatus === null
                            )?_c('p',{staticClass:"title"},[_vm._v(" You will be redirected to "),_c('code',[_vm._v(_vm._s(_vm.order.paymentGatewayName))]),_vm._v(" to complete the payment of "+_vm._s(_vm._f("currency")(_vm.order.paymentAmount,_vm.order.paymentCurrency))+". ")]):_vm._e(),(
                                _vm.order.requiresPaymentRedirect &&
                                !_vm.order.hasCryptoCurrencyPayment &&
                                _vm.paymentStatus !== null
                            )?_c('p',{staticClass:"title"},[_vm._v(" We are currently checking the status of your payment on "),_c('code',[_vm._v(_vm._s(_vm.order.paymentGatewayName))])]):_vm._e(),(
                                _vm.order.hasCryptoCurrencyPayment &&
                                ((_vm.order.requiresPaymentRedirect &&
                                    _vm.paymentStatus != null) ||
                                    (_vm.paymentStatus == null &&
                                        !_vm.order.requiresPaymentRedirect))
                            )?_c('p',{staticClass:"title"},[_vm._v(" We are currently waiting for the confirmation of your payment from "),_c('code',[_vm._v(_vm._s(_vm.order.paymentGatewayName))])]):_vm._e(),(
                                _vm.order.hasCryptoCurrencyPayment &&
                                !(
                                    _vm.order.requiresPaymentRedirect &&
                                    _vm.paymentStatus === null
                                )
                            )?_c('p',{staticClass:"subtitle-1"},[_vm._v(" It usually takes less than 3 minutes to receive the confirmation ")]):_vm._e(),(_vm.order.isReferred)?_c('alert-box',{attrs:{"no-icon":true}},[_vm._v(_vm._s(_vm.order.referralMessage))]):_vm._e()],1):_vm._e(),(_vm.order.isFulfilling && _vm.order.isDstv)?_c('div',{staticClass:"d-block mx-auto text-center mt-5 mb-8"},[_c('p',{staticClass:"title"},[_vm._v(" We have received your payment. "),_c('br'),_vm._v(" Your DSTV account is currently being credited automatically. ")]),_c('p',{staticClass:"subtitle-1"},[_vm._v(" It usually takes less than 1 minute to credit your account "),_c('br'),_c('router-link',{staticClass:"text-decoration-none font-weight-bold",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .CONTACT_US_INDEX,
                                }}},[_vm._v("Contact us")]),_vm._v(" if it is taking too long. ")],1)]):_vm._e(),(_vm.order.isFulfilling && _vm.order.isCanalplus)?_c('div',{staticClass:"d-block mx-auto text-center mt-5 mb-8"},[_c('p',{staticClass:"title"},[_vm._v(" We have received your payment. "),_c('br'),_vm._v(" Your CANAL+ account is currently being credited automatically. ")]),_c('p',{staticClass:"subtitle-1"},[_vm._v(" It usually takes less than 1 minute to credit your account "),_c('br'),_c('router-link',{staticClass:"text-decoration-none font-weight-bold",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .CONTACT_US_INDEX,
                                }}},[_vm._v("Contact us")]),_vm._v(" if it is taking too long. ")],1)]):_vm._e(),(_vm.order.isFulfilling && _vm.order.isAirtime)?_c('div',{staticClass:"d-block mx-auto text-center mt-5 mb-8"},[_c('p',{staticClass:"title"},[_vm._v(" We have received your payment. "),_c('br'),_vm._v(" We are currently transferring "+_vm._s(_vm._f("currency")(_vm.order.quantity))+" of airtime automatically. ")]),_c('p',{staticClass:"subtitle-1"},[_vm._v(" It usually takes less than 1 minute to transfer the airtime. "),_c('br'),_c('router-link',{staticClass:"text-decoration-none font-weight-bold",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .CONTACT_US_INDEX,
                                }}},[_vm._v("Contact us")]),_vm._v(" if it is taking too long. ")],1)]):_vm._e(),(_vm.order.isFulfilling && _vm.order.isEneoPrepaid)?_c('div',{staticClass:"d-block mx-auto text-center mt-5 mb-8"},[_c('p',{staticClass:"title"},[_vm._v(" We have received your payment. "),_c('br'),_vm._v(" We are currently getting the "),_c('code',[_vm._v("ENEO")]),_vm._v(" code for "+_vm._s(_vm._f("currency")(_vm.order.quantity))+" automatically. ")]),_c('p',{staticClass:"subtitle-1"},[_vm._v(" It usually takes less than 1 minute to get the code. "),_c('br'),_c('router-link',{staticClass:"text-decoration-none font-weight-bold",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .CONTACT_US_INDEX,
                                }}},[_vm._v("Contact us")]),_vm._v(" if it is taking too long. ")],1)]):_vm._e(),(_vm.order.isFulfilling && _vm.order.isEneoPostpaid)?_c('div',{staticClass:"d-block mx-auto text-center mt-5 mb-8"},[_c('p',{staticClass:"title"},[_vm._v(" We have received your payment. "),_c('br'),_vm._v(" We are currently paying your "),_c('code',[_vm._v("ENEO")]),_vm._v(" bill automatically. ")]),_c('p',{staticClass:"subtitle-1"},[_vm._v(" It usually takes less than 1 minute to pay the bill. "),_c('br'),_c('router-link',{staticClass:"text-decoration-none font-weight-bold",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .CONTACT_US_INDEX,
                                }}},[_vm._v("Contact us")]),_vm._v(" if it is taking too long. ")],1)]):_vm._e(),(_vm.order.isFulfilling && _vm.order.isCamwater)?_c('div',{staticClass:"d-block mx-auto text-center mt-5 mb-8"},[_c('p',{staticClass:"title"},[_vm._v(" We have received your payment. "),_c('br'),_vm._v(" We are currently paying your "),_c('code',[_vm._v("camwater")]),_vm._v(" bill automatically. ")]),_c('p',{staticClass:"subtitle-1"},[_vm._v(" It usually takes less than 1 minute to pay the bill. "),_c('br'),_c('router-link',{staticClass:"text-decoration-none font-weight-bold",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .CONTACT_US_INDEX,
                                }}},[_vm._v("Contact us")]),_vm._v(" if it is taking too long. ")],1)]):_vm._e(),(
                            _vm.order.isFulfilling &&
                            (_vm.order.isCryptoSell || _vm.order.isExchange)
                        )?_c('div',{staticClass:"d-block mx-auto text-center mt-5 mb-8"},[_c('p',{staticClass:"title"},[_vm._v(" We have received your payment. "),_c('br'),_vm._v(" We are currently paying "+_vm._s(_vm._f("currency")(_vm.order.creditsAdded))+" to your phone number "),_c('code',{staticClass:"font-weight-bold"},[_vm._v("("+_vm._s(_vm.order.recipientPhoneNumber)+")")]),_vm._v(" automatically. ")]),_c('p',{staticClass:"subtitle-1"},[_vm._v(" It usually takes less than 1 minute to transfer the funds. "),_c('br'),_c('router-link',{staticClass:"text-decoration-none font-weight-bold",attrs:{"to":{
                                    name: _vm.$constants.ROUTE_NAMES
                                        .CONTACT_US_INDEX,
                                }}},[_vm._v("Contact us")]),_vm._v(" if it is taking too long. ")],1)]):_vm._e(),(_vm.order.isFailedAtPayment)?_c('div',{staticClass:"d-block mx-auto text-center"},[_c('p',{staticClass:"title"},[_vm._v(" Your order with ID: "),_c('strong',[_vm._v(_vm._s(_vm.order.orderReference))]),_vm._v(" has status "),_c('code',[_vm._v(_vm._s(_vm.order.orderStatus))]),_vm._v("."),(_vm.order.isMobilePayment)?_c('span',[_vm._v("Usually it's because you did not pay in time. It may also be because you don't have "+_vm._s(_vm._f("currency")(_vm.order.paymentAmount))+" in your "+_vm._s(_vm.order.customerPaymentMethod.toLowerCase())+" account.")]):_vm._e(),_c('br'),_vm._v(" If you have already paid, you will be refunded within 20 minutes. ")])]):_vm._e(),(_vm.order.isFulfilled)?_c('order-receipt',{attrs:{"order":_vm.order}}):_vm._e()],1),(_vm.order.isFulfilled)?_c('v-card-actions',{staticClass:"px-8 pb-6 mt-n8"},[(_vm.order.receiptPdfIsGenerated)?_c('v-btn',{staticClass:"px-3",attrs:{"block":_vm.$vuetify.breakpoint.mdAndDown,"target":"_blank","href":_vm.order.receiptDownloadURL,"color":"primary"},on:{"click":_vm.downloadPDFClicked}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.downloadIcon))]),_vm._v(" Download As PDF ")],1):_vm._e()],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('back-button',{staticClass:"d-block mx-auto"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }